import type { SerializedStyles, Theme } from "@emotion/react";
import type { HTMLProps, ReactNode } from "react";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";

import { DeprecatedRadio } from "./DeprecatedRadio";

import * as styles from "./Radio.styles";

interface Props extends HTMLProps<HTMLInputElement> {
  /**
   * Adds custom styles to the label text styling
   */
  labelStyle?: (SerializedStyles | ((theme: Theme) => SerializedStyles))[] | SerializedStyles;
  /**
   * Content to render alongside the radio button
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/inputs/Radio
 *
 *
 * @deprecated Use `Radio` from `@fullscript/aviary-web` instead.
 */
const Radio = (props: Props) => {
  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled) return <DeprecatedRadio {...props} />;

  const { id, labelStyle, hidden, disabled, children, checked, ...rest } = props;

  const labelStyles = () => {
    return [styles.radioWrapper, hidden && styles.hidden, disabled && styles.disabled, labelStyle];
  };

  const radioCircleStyles = () => {
    return [styles.circleContainer.base, disabled && styles.circleContainer.disabled];
  };

  return (
    <label css={labelStyles()}>
      <input
        id={id}
        type="radio"
        checked={checked}
        css={styles.radio}
        disabled={disabled}
        {...rest}
      />
      <div css={radioCircleStyles()}>
        <div css={styles.radioInner} />
      </div>
      <div css={styles.radioContent}>{children}</div>
    </label>
  );
};

export { Radio };

// @ts-strict-ignore
import { type ReactiveVar, useReactiveVar } from "@apollo/client";

import { makeFSVar } from "@shared/reactiveVar/makeFSVar";
import { isDevelopment, isProduction, isStaging } from "@shared/utils/fullscriptEnv/fullscriptEnv";
import { getPersistedOverrides } from "@shared/utils/persistedExperimentOverrides/getPersistedOverrides";

type OverrideValues = { [experimentName: string]: string };
type Experiments = { [experimentName: string]: readonly string[] };

type ExperimentVar = {
  roleId?: string;
  userId?: string;
  storeId?: string;
  analyticsAnonymousId?: string;
  currentExperiments?: Experiments | {};
  overrideValues?: OverrideValues | {};
};

type ExperimentContextData = Pick<
  ExperimentVar,
  "userId" | "roleId" | "storeId" | "analyticsAnonymousId" | "overrideValues"
>;

type SetOverrideParams = {
  experimentName: string;
  group: string;
  isAdminImpersonating: boolean;
};

type SetCurrentExperimentsParams = {
  experimentName: string;
  groups: readonly string[];
  isAdminImpersonating: boolean;
};

type UseExperimentVar = ExperimentVar & {
  setOverride: ({ experimentName, group }: SetOverrideParams) => void;
  setCurrentExperiments: ({ experimentName, groups }: SetCurrentExperimentsParams) => void;
};

let experimentVar: ReactiveVar<ExperimentVar>;

const getInitialOverrideValues = () => {
  if (!isDevelopment() && !isStaging()) return {};

  return getPersistedOverrides();
};

/**
 * Initialize the experimentVar from useExperimentVar with some initial value.
 * Typically only used in Providers or entrypoints of applications
 * Subsequent calls will have no affect after this has been called once before
 *
 * @param experimentVarParams - The initial value for useExperimentVar
 */
const createExperimentVar = (experimentVarParams: ExperimentContextData = {}) => {
  experimentVar ||= makeFSVar<ExperimentVar>(
    {
      roleId: null,
      userId: null,
      storeId: null,
      analyticsAnonymousId: null,
      overrideValues: getInitialOverrideValues(),
      ...experimentVarParams,
      currentExperiments: {},
    },
    "experimentVar"
  );
};

/**
 * Used to force/override an experiment to be the specified group
 *
 * @param experimentName - The experiment to override
 * @param group - The group to be part of for the specified experiment ex: "control" or "experiment"
 */
const setOverride: UseExperimentVar["setOverride"] = ({
  experimentName,
  group,
  isAdminImpersonating,
}) => {
  if (isProduction() && !isAdminImpersonating) return;

  const { overrideValues, ...rest } = experimentVar();

  if (overrideValues[experimentName] === group) return;

  experimentVar({ ...rest, overrideValues: { ...overrideValues, [experimentName]: group } });
};

/**
 * Used to reset overrides for all experiments to their initial values
 */
const clearOverrides = () => {
  const rest = experimentVar();
  experimentVar({ ...rest, overrideValues: {} });
};

/**
 * Used to specify groups for an experiment, only to be used via useExperiment, not to be used directly
 *
 * @param experimentName - The experiment to create
 * @param groups - Groups for said experiment
 */
const setCurrentExperiments: UseExperimentVar["setCurrentExperiments"] = ({
  experimentName,
  groups,
  isAdminImpersonating,
}) => {
  if (isProduction() && !isAdminImpersonating) return;

  const { currentExperiments, ...rest } = experimentVar();

  if (currentExperiments[experimentName]) return;

  experimentVar({
    ...rest,
    currentExperiments: { ...currentExperiments, [experimentName]: groups },
  });
};

/**
 * @deprecated DO NOT USE! - For internal testing infra purposes only
 */
const clearExperimentVar = () => {
  experimentVar = undefined;
};

/**
 * Hook that provides global access to experiment data.
 * Gives you access to userId, storeId, roleId, currentExperiment and any overrides
 */
const useExperimentVar = (): UseExperimentVar => {
  // initialize with default values if not done already
  createExperimentVar();

  const reactiveVar = useReactiveVar(experimentVar);

  return { ...reactiveVar, setOverride, setCurrentExperiments };
};

export {
  useExperimentVar,
  createExperimentVar,
  clearOverrides,
  clearExperimentVar,
  type ExperimentContextData,
  type OverrideValues,
};

import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { NoStyleButton } from "@fullscript/aviary-web";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@shared/react-fontawesome/react-fontawesome";
import { useNavigate } from "@shared/react-router-dom/react-router-dom";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { noStyleButton, chevron } from "./ReturnToSignInButton.styles";

const ReturnToSignInButton = ({ ...rest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <NoStyleButton onClick={handleGoBack} customCss={noStyleButton} {...rest}>
      <FontAwesomeIcon className="icon-chevron" icon={faChevronLeft} size="sm" css={chevron} />
      {t(l.signIn.ReturnToSignIn)}
    </NoStyleButton>
  );
};

export { ReturnToSignInButton };

// @ts-strict-ignore
import type { Dispatch, FormEvent, SetStateAction } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Arrange } from "@aviary";
import { EmailInput } from "@shared/components/EmailInput/EmailInput";
import {
  isLengthValid,
  isEmailAtValid,
} from "@shared/components/EmailInput/EmailValidation/EmailValidation";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { ReturnToSignInButton } from "../ReturnToSignInButton/ReturnToSignInButton";

import * as styles from "./ForgotPasswordForm.styles";

interface Props {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}

const ForgotPasswordForm = ({ email, onSubmit, isLoading, setEmail }: Props) => {
  const { tablet } = useBreakpoints();
  const { t } = useTranslation();
  const [isEmailDirty, setIsEmailDirty] = useState(false);

  const handleEmailOnBlur = e => {
    if (e.target.value) {
      setIsEmailDirty(true);
    }
  };

  const isFormValid = !!email && isEmailAtValid(email) && isLengthValid(email);

  return (
    <form css={styles.form} onSubmit={onSubmit}>
      <EmailInput
        onChange={e => setEmail(e.target.value)}
        onBlur={handleEmailOnBlur}
        required
        isDirty={isEmailDirty}
        value={email}
        setEmail={setEmail}
        autoFocus={!email.length}
        wrapperStyles={styles.input}
      />

      <Arrange isReversed={tablet.lessThan} isVertical={tablet.lessThan} alignment="center">
        <ReturnToSignInButton />

        <Button
          isLoading={isLoading}
          disabled={!isFormValid}
          type="submit"
          isFullWidth={tablet.lessThan}
        >
          {t(l.signIn.EmailMeLink)}
        </Button>
      </Arrange>
    </form>
  );
};

export { ForgotPasswordForm };

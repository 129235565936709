import { useRef } from "react";

import { debounce } from "@shared/utils/debounce/debounce";

const useDebounce = <T extends (...args: any[]) => any>(fn: T, delay: number) => {
  const ref = useRef(debounce(fn, delay));

  // eslint-disable-next-line react-compiler/react-compiler
  return ref.current;
};

export { useDebounce };

import { css, type Theme } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";
import * as layers from "@styles/emotion-styles/layers";

export const buttonsContainer = css`
  display: flex;

  @media (max-width: ${dimensions.tablet}) {
    flex-direction: row-reverse;
  }
`;

export const navBar = (theme: Theme) => css`
  display: flex;
  padding: 1rem;
  position: sticky;
  top: 0;
  background: ${theme.light.backgroundBase};
  border-bottom: 1px solid ${theme.system.borderBase};
  z-index: ${layers.indexNavBar};
`;

export const button = css`
  margin-right: 1rem;

  @media (max-width: ${dimensions.tablet}) {
    margin: 0 0 0 0.5rem;
  }
`;

export const svgContainer = css`
  width: 7rem;
`;

import { useTranslation } from "react-i18next";

import { Pill } from "@aviary";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import type { PatientAttributes } from "@unauthenticated/shared/data/types/PatientAttributes.d";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreOffersPill.styles";
interface Props {
  patientAttributes: PatientAttributes;
}

const StoreOffersPill = ({ patientAttributes }: Props) => {
  const { t } = useTranslation();
  const { isUS } = useSharedGlobalConfig();
  const { storeDiscount } = patientAttributes;

  const renderDiscountPill = () => {
    if (!storeDiscount) return null;

    return (
      <Pill isColor="success" css={styles.pill}>
        {t(l.sharedCommon.discountPill, { discount: storeDiscount })}
      </Pill>
    );
  };

  const renderFreeShipping = () => {
    const { freeShippingThreshold } = patientAttributes;

    const hasFreePriorityShipping = !isUS || patientAttributes.storeHasFreePriorityShipping;
    const testId = hasFreePriorityShipping ? "priority-shipping-pill" : "standard-shipping-pill";

    if (freeShippingThreshold === 0) {
      return (
        <Pill data-testid={testId} isColor="success" css={styles.pill}>
          {hasFreePriorityShipping
            ? t(l.patientRxAuth.FreePriorityShippingZeroThreshold)
            : t(l.patientRxAuth.FreeStandardShippingZeroThreshold)}
        </Pill>
      );
    }

    if (!freeShippingThreshold) return null;

    return (
      <Pill data-testid={testId} isColor="success" css={styles.pill}>
        {hasFreePriorityShipping
          ? t(l.patientRxAuth.FreePriorityShipping, {
              freeShippingThreshold,
            })
          : t(l.patientRxAuth.FreeStandardShipping, {
              freeShippingThreshold,
            })}
      </Pill>
    );
  };

  return (
    <div css={styles.pillContainer}>
      {renderDiscountPill()}
      {renderFreeShipping()}
    </div>
  );
};

export { StoreOffersPill };

// @ts-strict-ignore
import type { HTMLProps, ReactNode } from "react";

import type { Sizes, Width } from "../grid";

import * as styles from "./Column.styles";

type WidthOptions = Sizes | Width;

interface ColumnProps extends HTMLProps<HTMLDivElement> {
  /**
   * Specifies the width of the column, out of 12
   *
   */
  columnWidth?: WidthOptions;
  /**
   * Specifies the offset of the column, out of 12
   *
   */
  columnOffset?: WidthOptions;
  /**
   * Removes all padding within the column
   *
   * @default false
   */
  isPaddingless?: boolean;
  /**
   * Specifies the text alignment within the column
   *
   * @default left
   */
  textAlign?: "left" | "center" | "right";
  /**
   * Allows columns to be preserved on mobile, instead of stacking
   *
   * @default false
   */
  preserveMobileColumns?: boolean;
  /**
   * Content to render within the column
   *
   * @default undefined
   */
  children?: ReactNode;
  "data-testid"?: string;
}

/**
 * @deprecated Use `Columns.Column` from `@fullscript/aviary-web` instead.
 */
const Column = ({
  columnWidth,
  columnOffset,
  isPaddingless,
  textAlign,
  children,
  preserveMobileColumns,
  "data-testid": unusedDataTestId,
  ...rest
}: ColumnProps) => {
  const isSizes = (gridType: WidthOptions): gridType is Sizes => {
    return typeof gridType === "number";
  };

  const isWidth = (gridType: WidthOptions): gridType is Width => {
    return gridType === "full" || gridType === "narrow";
  };

  const makeWidth = () => {
    if (isSizes(columnWidth) && preserveMobileColumns) {
      return styles.columnWidths(columnWidth);
    }
    if (isSizes(columnWidth)) {
      return styles.sizes(columnWidth);
    }
    if (isWidth(columnWidth)) {
      return styles.widthOptions[columnWidth];
    }
  };

  const makeOffset = () => {
    if (isSizes(columnOffset)) {
      return styles.offsetSize(columnOffset);
    }
  };
  const columnBuilder = [
    styles.column,
    columnWidth && makeWidth(),
    columnOffset && makeOffset(),
    isPaddingless && styles.paddingless,
    textAlign && styles.textAlignment[textAlign],
  ];

  return (
    <div css={columnBuilder} {...rest}>
      {children}
    </div>
  );
};

export type { ColumnProps, WidthOptions };
export { Column };
